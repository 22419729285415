import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { Link } from 'react-router-dom';

const News = () => {
  const { t } = useTranslation();

  const newsData = {
    exchangeNews: [
      {
        id: 1,
        date: '10.05.2024',
        title: t('news.exchange.generalMeeting2024'),
        href: '/assets/news/Газета ПДФ 10 мая 2024, 10  стр.pdf#page=10',
      },
      {
        id: 2,
        date: '14.11.2023',
        title: t('news.exchange.extraordinaryMeeting2023November'),
        href: '/assets/news/эркин тоо газета.pdf#page=14',
      },
      {
        id: 3,
        date: '20.10.2023',
        title: t('news.exchange.extraordinaryMeeting2023October'),
        href: '/assets/news/Газета эркин тоо.pdf#page=10',
      },
    ],
    companyNews: [
      {
        id: 1,
        date: '30.07.2024',
        title: t('news.company.royalQ2'),
        href: '/assets/news/royal.pdf',
      },
      {
        id: 2,
        date: '30.10.2024',
        title: t('news.company.royalQ3'),
        href: '/assets/news/royal_2.pdf',
      },
      {
        id: 3,
        date: '03.12.2024',
        title: t('news.company.keregitashFact'),
        href: '/assets/news/Керегиташ_сущ_факт.pdf',
      },
    ],
  };

  return (
    <div className='news-block'>
      <div className='container'>
        <h2 className='news-block__title title'>{t('news.title')}</h2>
        <div className='news-block__columns'>
          <div className='news-block__column'>
            <h3 className='news-block__subtitle'>{t('news.exchange.title')}</h3>
            <ul className='news-block__list'>
              {newsData.exchangeNews.map((news) => (
                <li key={news.id} className='news-block__item'>
                  <span className='news-block__date'>{news.date}</span>
                  <a target='_blank' rel='noreferrer' href={news.href}>
                    {news.title}
                  </a>
                </li>
              ))}
            </ul>
            <Link to='exchange-news' className='news-block__link'>
              {t('news.viewAll')} →
            </Link>
          </div>

          <div className='news-block__column'>
            <h3 className='news-block__subtitle'>{t('news.company.title')}</h3>
            <ul className='news-block__list'>
              {newsData.companyNews.reverse().map((news) => (
                <li key={news.id} className='news-block__item'>
                  <span className='news-block__date'>{news.date}</span>
                  <a target='_blank' rel='noreferrer' href={news.href}>
                    {news.title}
                  </a>
                </li>
              ))}
            </ul>
            <Link to='company-news' className='news-block__link'>
              {t('news.viewAll')} →
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
