import React from 'react';
import './styles.scss';

const NotFound = () => {
	return (
		<section className='not-found'>
			<div className='container'>
				<div className='not-found__content'>
					<div className='not-found__logo'>
						<svg
							width='120'
							height='60'
							viewBox='0 0 40 20'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M23.47 3.12979C24.7674 3.12979 26.0511 3.38378 27.2318 3.87803C25.5088 1.66763 22.8317 0.342773 19.9897 0.342773C19.1728 0.342773 18.3696 0.445742 17.6077 0.658544C14.45 1.50288 11.965 3.98786 11.1206 7.14555C10.9079 7.90752 10.8049 8.71067 10.8049 9.52755C10.8049 12.3695 12.1366 15.0467 14.3401 16.7697C13.8528 15.5821 13.5919 14.3053 13.5919 13.0079C13.5919 12.6235 13.6125 12.2803 13.6537 11.9439C14.1342 7.35838 17.8205 3.67896 22.4061 3.19157C22.7355 3.15038 23.0787 3.12979 23.4632 3.12979H23.47Z'
								fill='white'
							/>
							<path
								d='M23.9229 13.6402C22.6805 14.1825 21.3624 14.4571 19.9964 14.4571C17.9508 14.4571 15.9532 13.8187 14.2988 12.638C14.2988 12.7547 14.2988 12.8783 14.2988 13.0018C14.2988 14.5944 14.7107 16.1526 15.4933 17.5187C16.1591 17.8962 16.873 18.1914 17.6281 18.3905C18.3901 18.6033 19.1932 18.7062 20.0101 18.7062C25.0762 18.7062 29.2018 14.5875 29.2018 9.51458C29.2018 9.1233 29.1743 8.73888 29.1332 8.36133C28.1583 10.6953 26.2844 12.6105 23.9435 13.6264L23.9229 13.6402Z'
								fill='white'
							/>
							<path
								d='M28.8451 7.04962C28.6459 6.32887 28.3577 5.6424 27.9939 5.004C26.6278 4.22144 25.0626 3.80957 23.4701 3.80957C23.3465 3.80957 23.223 3.80957 23.0994 3.81643C24.2732 5.47766 24.9116 7.47522 24.9116 9.51406C24.9116 10.5849 24.7469 11.6283 24.4105 12.6168C26.5523 11.4292 28.1792 9.40417 28.8313 7.04276L28.8451 7.04962Z'
								fill='#F48020'
							/>
							<path
								d='M7.90115 9.52767C7.90115 7.17998 8.57383 4.99018 9.72709 3.12988C4.96995 5.57367 1.79852 8.86178 0 11.0791C1.13265 12.6511 3.10965 14.9576 6.19871 16.7492C8.29243 17.9642 10.441 18.6781 12.5416 19.0488C9.71336 16.8316 7.89429 13.3924 7.89429 9.5208L7.90115 9.52767Z'
								fill='white'
							/>
							<path
								d='M39.993 7.96977C38.8604 6.39778 36.8834 4.09128 33.7943 2.29963C31.7006 1.0846 29.552 0.370687 27.4514 0C30.2797 2.21725 32.0988 5.65641 32.0988 9.52803C32.0988 11.8757 31.426 14.0655 30.2728 15.9189C35.0299 13.482 38.2013 10.187 39.9999 7.96977H39.993Z'
								fill='#F48020'
							/>
						</svg>
					</div>
					<h1 className='not-found__title'>404 Page Not Found </h1>
				</div>
			</div>
		</section>
	);
};

export default NotFound;
