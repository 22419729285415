import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import './styles.scss';

const Confidentiality = () => {
  const { t } = useTranslation();

  const sections = [
    {
      title: t('confidentiality.intro_title'),
      content: <Trans i18nKey="confidentiality.intro" />,
    },
    {
      title: t('confidentiality.sections.info_title'),
      content: (
        <>
          <p>{t('confidentiality.sections.info_text')}</p>
          <ul className='confidentiality__list'>
            {t('confidentiality.sections.info_list', { returnObjects: true }).map((item, index) => (
              <li key={index} className='confidentiality__list-item'>
                <Icon />
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: t('confidentiality.sections.individual_title'),
      content: (
        <ul className='confidentiality__list'>
          {t('confidentiality.sections.individual_list', { returnObjects: true }).map((item, index) => (
            <li key={index} className='confidentiality__list-item'>
              <Icon />
              <p>{item}</p>
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: t('confidentiality.sections.entity_title'),
      content: (
        <ul className='confidentiality__list'>
          {t('confidentiality.sections.entity_list', { returnObjects: true }).map((item, index) => (
            <li key={index} className='confidentiality__list-item'>
              <Icon />
              <p>{item}</p>
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: t('confidentiality.sections.rights_title'),
      content: (
        <>
          <p>{t('confidentiality.sections.rights_text')}</p>
          <ul className='confidentiality__list'>
            {t('confidentiality.sections.rights_list', { returnObjects: true }).map((right, index) => (
              <li key={index} className='confidentiality__list-item'>
                <Icon />
                <div>
                  <strong>{right.title}</strong>
                  <p>{right.description}</p>
                </div>
              </li>
            ))}
          </ul>
          <p>{t('confidentiality.sections.additional_info')}</p>
        </>
      ),
    },
    {
      title: t('confidentiality.sections.data_collection_title'),
      content: (
        <>
          <p>{t('confidentiality.sections.data_collection_text')}</p>
          <ul className='confidentiality__list'>
            {t('confidentiality.sections.data_collection_points', { returnObjects: true }).map((item, index) => (
              <li key={index} className='confidentiality__list-item'>
                <Icon />
                <p>{item}</p>
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: t('confidentiality.sections.operations_title'),
      content: (
        <>
          <p>{t('confidentiality.sections.operations_text')}</p>
          <ul className='confidentiality__list'>
            {t('confidentiality.sections.operations_list', { returnObjects: true }).map((operation, index) => (
              <li key={index} className='confidentiality__list-item'>
                <Icon />
                <p>{operation}</p>
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: t('confidentiality.sections.data_usage_title'),
      content: (
        <>
          <p>{t('confidentiality.sections.data_usage_text')}</p>
          <ul className='confidentiality__list'>
            {t('confidentiality.sections.data_usage_list', { returnObjects: true }).map((usage, index) => (
              <li key={index} className='confidentiality__list-item'>
                <Icon />
                <div>
                  <strong>{usage.title}</strong>
                  <p>{usage.description}</p>
                </div>
              </li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: t('confidentiality.sections.data_storage_title'),
      content: <p>{t('confidentiality.sections.data_storage_text')}</p>,
    },
    {
      title: t('confidentiality.sections.data_transfer_title'),
      content: <p>{t('confidentiality.sections.data_transfer_text')}</p>,
    },
    {
      title: t('confidentiality.sections.cookies_title'),
      content: (
        <>
          <p>{t('confidentiality.sections.cookies_text')}</p>
          <p>{t('confidentiality.sections.cookies_disabling_text')}</p>
        </>
      ),
    },
    {
      title: t('confidentiality.sections.data_security_title'),
      content: <p>{t('confidentiality.sections.data_security_text')}</p>,
    },
    {
      title: t('confidentiality.sections.account_deletion_title'),
      content: (
        <>
          <p>{t('confidentiality.sections.account_deletion_text')}</p>
          <ul className='confidentiality__list'>
            {t('confidentiality.sections.account_deletion_consequences', { returnObjects: true }).map((consequence, index) => (
              <li key={index} className='confidentiality__list-item'>
                <Icon />
                <p>{consequence}</p>
              </li>
            ))}
          </ul>
          <p>{t('confidentiality.sections.account_deletion_note')}</p>
        </>
      ),
    },
    {
      title: t('confidentiality.sections.notifications_title'),
      content: <p>{t('confidentiality.sections.notifications_text')}</p>,
    },
  ];

  return (
    <section className='confidentiality'>
      <div className='container'>
        <h1 className='title'>{t('confidentiality.title')}</h1>
        <div className='confidentiality__content'>
          {sections.map((section, index) => (
            <div key={index} className='confidentiality__info'>
              <h2 className='confidentiality__title'>{section.title}</h2>
              {section.content}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Icon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden='true'
    role='img'
    width='25px'
    height='25px'
    viewBox='0 0 24 24'
  >
    <path
      fill='#f48020'
      d='M20 12a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8c.76 0 1.5.11 2.2.31l1.57-1.57A9.8 9.8 0 0 0 12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10M7.91 10.08L6.5 11.5L11 16L21 6l-1.41-1.42L11 13.17z'
    ></path>
  </svg>
);

export default Confidentiality;
