import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const Contacts = () => {
  const { t } = useTranslation();

  return (
    <section className='contacts'>
      <div className='container'>
        <h1 className='contacts__title'>{t('contacts.title')}</h1>
        <div className='contacts__content'>
          <div className='contacts__info'>
            <ul className='contacts__info-list'>
              <li className='contacts__info-item'>
                {t('contacts.phone')}: +996 220 142 170
              </li>
              <li className='contacts__info-item'>
                {t('contacts.email')}: info@envoys.vision
              </li>
              <li className='contacts__info-item'>
                {t('contacts.address')}: {t('contacts.addressDetails')}
              </li>
            </ul>
          </div>
          <div className='contacts__map'>
            <iframe
              title={t('contacts.mapTitle')}
              src='https://yandex.com/map-widget/v1/?um=constructor%3Accabb189460d92caf0e7d2c88a7bd06a5e12cb24bb2b2abe1cc70040990ae953&amp;source=constructor'
              width='100%'
              height='350'
              style={{ border: 0 }}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
