import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import NewsImage1 from '../../../images/evde-news.png';
import NewsImage2 from '../../../images/evde-news2.png';
import NewsImage3 from '../../../images/evde-news3.png';
import NewsImage4 from '../../../images/evde-news4.png';

const ExchangeNews = () => {
  const { t } = useTranslation();

  const news = [
    {
      title: t('exchangeNews.news.generalMeeting2023'),
      desc: '',
      date: t('exchangeNews.news.generalMeeting2023Date'),
      image: NewsImage1,
      link: '/assets/news/эркинтооГОСА2023.pdf#page=11',
    },
    {
      title: t('exchangeNews.news.extraordinaryMeeting2023October'),
      desc: '',
      date: t('exchangeNews.news.extraordinaryMeeting2023OctoberDate'),
      image: NewsImage2,
      link: '/assets/news/Газета эркин тоо.pdf#page=10',
    },
    {
      title: t('exchangeNews.news.extraordinaryMeeting2023November'),
      desc: '',
      date: t('exchangeNews.news.extraordinaryMeeting2023NovemberDate'),
      image: NewsImage3,
      link: '/assets/news/эркин тоо газета.pdf#page=14',
    },
    {
      title: t('exchangeNews.news.generalMeeting2024'),
      desc: '',
      date: t('exchangeNews.news.generalMeeting2024Date'),
      image: NewsImage4,
      link: '/assets/news/Газета ПДФ 10 мая 2024, 10  стр.pdf#page=10',
    },
  ];

  return (
    <>
      <section className='news'>
        <div className='container'>
          <h1 className='news__title title'>{t('exchangeNews.title')}</h1>
          <div className='news__content'>
            {news.reverse().map((newsItem, index) => (
              <div key={index} className='news__col'>
                <a
                  href={newsItem.link}
                  target='_blank'
                  className='news__card'
                  rel='noopener noreferrer'
                >
                  <div className='news__card-image--block'>
                    <img
                      src={newsItem.image}
                      className='news__card-img'
                      alt={t('exchangeNews.alt')}
                    />
                  </div>
                  <span className='news__card-date'>{newsItem.date}</span>
                  <h3 className='news__card-title'>{newsItem.title}</h3>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ExchangeNews;
