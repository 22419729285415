import React from 'react';
import Listing from '../listing/Listing';
import News from '../news/News';
import Hero from '../hero/Hero';
import AboutUs from '../about_us/AboutUs';
import Partners from '../partners/Partners';

const Home = () => {
	return (
		<>
			<Hero />
			<AboutUs />
			{/* <Listing /> */}
			<News />
			<Partners />
		</>
	);
};

export default Home;
