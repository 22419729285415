import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import NewsImage1 from '../../../images/royal-news.png';
import NewsImage2 from '../../../images/royal-news2.png';
import NewsImage3 from '../../../images/keregi-news.png';

const CompanyNews = () => {
  const { t } = useTranslation();

  const [news] = useState([
    {
      title: t('companyNews.news.royalQ2.title'),
      desc: '',
      date: t('companyNews.news.royalQ2.date'),
      image: NewsImage1,
      link: '/assets/news/royal.pdf',
    },
    {
      title: t('companyNews.news.royalQ3.title'),
      desc: '',
      date: t('companyNews.news.royalQ3.date'),
      image: NewsImage2,
      link: '/assets/news/royal_2.pdf',
    },
    {
      title: t('companyNews.news.keregitashFact.title'),
      desc: '',
      date: t('companyNews.news.keregitashFact.date'),
      image: NewsImage3,
      link: '/assets/news/Керегиташ_сущ_факт.pdf',
    },
  ]);

  return (
    <>
      <section className='news'>
        <div className='container'>
          <h1 className='news__title title'>{t('companyNews.title')}</h1>
          <div className='news__content'>
            {news.reverse().map((newsItem, index) => (
              <div key={index} className='news__col'>
                <a
                  href={newsItem.link}
                  target='_blank'
                  className='news__card'
                  rel='noopener noreferrer'
                >
                  <div className='news__card-image--block'>
                    <img
                      src={newsItem.image}
                      className='news__card-img'
                      alt={t('companyNews.alt')}
                    />
                  </div>
                  <span className='news__card-date'>{newsItem.date}</span>
                  <h3 className='news__card-title'>{newsItem.title}</h3>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default CompanyNews;
