import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import './styles.scss';

const AboutUs = () => {
	const { t } = useTranslation();

	return (
		<section className='about_us'>
			<div className='container'>
				<div className='about_us__content'>
					<h1 className='about_us__title title'>
						<Trans i18nKey="about_us.title">
							<span className="about_us__title_yellow">Envoys Vision</span> Digital exchange
						</Trans>
					</h1>
					<div className='about_us__block'>
						<p className='about_us__desc'>
							{t('about_us.description')}
						</p>
						<div className='about_us__link-block'>
							<Link to='/about' className='about_us__link'>
								{t('about_us.link')}
								<svg
									className='about_us__link-icon'
									width='10'
									height='10'
									viewBox='0 0 10 10'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8.00208 3.0605L1.54683 9.51575L0.486328 8.45525L6.94083 2H1.25208V0.5H9.50208V8.75H8.00208V3.0605Z'
										fill='current'
									></path>
								</svg>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;

