import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const RiskInfo = () => {
  const { t } = useTranslation();

  const infoList = t('riskInfo.risks', { returnObjects: true });

  return (
    <section className='risk'>
      <div className='container'>
        <h1 className='title'>{t('riskInfo.title')}</h1>
        <div className='risk__content'>
          <div className='risk__info'>
            <h2 className='risk__title'>{t('riskInfo.header1')}</h2>
            <p className='risk__text fz_16'>{t('riskInfo.text1')}</p>
          </div>
          <div className='risk__info'>
            <h2 className='risk__title'>{t('riskInfo.header2')}</h2>
            <p className='risk__text'>{t('riskInfo.text2')}</p>
            <ul className='risk__list'>
              {infoList.map((item, index) => (
                <li key={index} className='risk__list-item'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    aria-hidden='true'
                    role='img'
                    width='25px'
                    height='25px'
                    viewBox='0 0 24 24'
                  >
                    <path
                      fill='#f48020'
                      d='M20 12a8 8 0 0 1-8 8a8 8 0 0 1-8-8a8 8 0 0 1 8-8c.76 0 1.5.11 2.2.31l1.57-1.57A9.8 9.8 0 0 0 12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10M7.91 10.08L6.5 11.5L11 16L21 6l-1.41-1.42L11 13.17z'
                    ></path>
                  </svg>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className='risk__info'>
            <h2 className='risk__title'>{t('riskInfo.header3')}</h2>
            <p className='risk__text'>{t('riskInfo.text3')}</p>
          </div>
          <div className='risk__info'>
            <h2 className='risk__title'>{t('riskInfo.header4')}</h2>
            <p className='risk__text'>{t('riskInfo.text4')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RiskInfo;
