import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const Onboard = () => {
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;

	const onboardLinks = [
		{
			text: t('onboard.documentsList'),
			href:
				currentLanguage === 'en'
					? 'https://docs.google.com/document/d/1H_5uGmx6MekJwuQLUYkycxmFuudTV4jk/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true'
					: 'https://docs.google.com/document/d/1Bp0y9cXRnj4ejxK_iV7dXf7n1kkF-3BH/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		{
			text: t('onboard.signatureCard'),
			href: 'https://docs.google.com/document/d/1MNM4E7pGKnTt3g-ci5_LmCibg5AGVWMI/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		{
			text: t('onboard.partnerFormLegal'),
			href:
				currentLanguage === 'en'
					? 'https://docs.google.com/document/d/1w3VGmHBNbMdDYY2zlC87CM_qfC_6ZLZ_/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true'
					: 'https://docs.google.com/document/d/1hWwtTL27zft6vZOuo0dknFb2_on2z6c5/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		{
			text: t('onboard.clientFormLegal'),
			href:
				currentLanguage === 'en'
					? 'https://docs.google.com/document/d/1ptwofdw4_YMLVSbBreRu2WcRkBgmSmxv/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true'
					: 'https://docs.google.com/document/d/1gaZh_Z5zoQVcgrhYf2RY4946qN5uWHZ1/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
		// {
		// 	text: t('onboard.partnerFormLegalEn'),
		// 	href: 'https://docs.google.com/document/d/1w3VGmHBNbMdDYY2zlC87CM_qfC_6ZLZ_/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		// },
		{
			text: t('onboard.clientFormIndividual'),
			href:
				currentLanguage === 'en'
					? 'https://docs.google.com/document/d/1Zx-CZeZViJethTzQsDgX1UgtVJyglbnY/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true'
					: 'https://docs.google.com/document/d/1w40TPzFohuUvZFL3MhM2nIUDK-yMG4s_/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
		},
	];

	return (
		<div className='onboard'>
			<h3 className='onboard__title'>{t('onboard.title')}</h3>
			<ul className='onboard__list'>
				{onboardLinks.map((item, index) => (
					<li key={index} className='onboard__item'>
						<a target='_blank' rel='noopener noreferrer' href={item.href}>
							{item.text}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Onboard;
