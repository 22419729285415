import React from 'react';
import { useTranslation } from 'react-i18next';
import Video from '../../images/hero/cover.mp4';
import './styles.scss';

const Hero = () => {
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;

	return (
		<section className='hero'>
			<div className='hero__video-block'>
				<video
					autoPlay={true}
					loop={true}
					playsInline={true}
					muted={true}
					className='hero__video'
				>
					<source src={Video} type='video/mp4' />
				</video>
			</div>
			<div className='hero__info'>
				<h1 className='hero__title'>{t('hero.title')}</h1>
				<p className='hero__text'>{t('hero.description')}</p>
				<div className='hero__presentation_block'>
					<a
						href={
							currentLanguage === 'en'
								? 'https://drive.google.com/file/d/17_iE4F9wriWruw5Yy3MD9Qvo6verB5xU/view?usp=sharing'
								: 'https://drive.google.com/file/d/1KKGtSQtYqfJ0Tb1zfoWlD0hf5NJlN73q/view?usp=sharing'
						}
						className='hero__presentation'
						target='_blank'
						rel='noopener noreferrer'
					>
						{t('hero.presentation')}
					</a>
				</div>
			</div>
		</section>
	);
};

export default Hero;
