import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import AboutImg1 from '../../images/about/about1.png';
import AboutImg2 from '../../images/about/about2.png';
import AboutImg3 from '../../images/about/about3.png';
import AboutDocuments from './about_documents/AboutDocuments';

const About = () => {
	const { t } = useTranslation(); 

  return (
    <section className='about'>
      <div className='container'>
        <div className='about__df'>
          <div className='about__info'>
            <h1 className='about__title title'>
              {t('about.company')} <span className='about__title-orange'>{t('about.company_name')}</span> {t('about.digital_exchange')}
            </h1>
            <p className='about__text'>{t('about.mission')}</p>
          </div>
          <div className='about__img-block'>
            <img src={AboutImg1} alt='about' className='about__img' />
          </div>
        </div>
        <AboutDocuments />
        <div className='about__df'>
          <div className='about__info'>
            <h1 className='about__title title'>{t('about.history_title')}</h1>
            <p className='about__text about__text-small'>{t('about.history')}</p>
            <a
              href='https://mail.google.com/mail/u/0/?fs=1&to=office@envoys.vision&tf=cm'
              target='_blank'
              rel='noreferrer'
              className='about__link'
            >
              {t('about.contact_us')}
            </a>
          </div>
          <div className='about__img-block'>
            <img src={AboutImg2} alt='about' className='about__img' />
          </div>
        </div>
        <div className='about__df about__df-reverse'>
          <div className='about__img-block'>
            <img src={AboutImg3} alt='about' className='about__img' />
          </div>
          <div className='about__info'>
            <h1 className='about__title title'>{t('about.platform_title')}</h1>
            <p className='about__text about__text-small'>{t('about.platform_description')}</p>
            <a
              href='https://mail.google.com/mail/u/0/?fs=1&to=office@envoys.vision&tf=cm'
              target='_blank'
              className='about__link'
            >
              {t('about.contact_us')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
