import { Route, Routes } from 'react-router-dom';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './components/home/Home';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import ExchangeNews from './components/news/exchange-news/ExchangeNews';
import CompanyNews from './components/news/company-news/CompanyNews';
import Statement from './components/statement/Statement';
import Documents from './components/documents/Documents';
import Listing from './components/listing/Listing';
import About from './components/about/About';
import Confidentiality from './components/confidentiality/Confidentiality';
import Contacts from './components/contacts/Contacts';
import RiskInfo from './components/risk_info/RiskInfo';
import NotFound from './components/not_found/NotFound';

function App() {
	return (
		<section className='wrap'>
			<Header />
			<main className='content'>
				<ScrollToTop />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/exchange-news' element={<ExchangeNews />} />
					<Route path='/listing' element={<Listing />} />
					<Route path='/company-news' element={<CompanyNews />} />
					<Route path='/statement' element={<Statement />} />
					<Route path='/documents' element={<Documents />} />
					<Route path='/about' element={<About />} />
					<Route path='/confidentiality' element={<Confidentiality />} />
					<Route path='/contacts' element={<Contacts />} />
					<Route path='/disclosure' element={<RiskInfo />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</main>
			<Footer />
		</section>
	);
}

export default App;
