import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const Listing = () => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState('companiesList');
	const [activeCompanyList, setActiveCompanyList] = useState('');

	// Массив табов
	const tabs = useMemo(
		() => [
			{ id: 'companiesList', label: t('listing.tabs.companiesList') },
			{ id: 'bonds', label: t('listing.tabs.bonds') },
			{ id: 'stocks', label: t('listing.tabs.stocks') },
			{ id: 'financialReports', label: t('listing.tabs.financialReports') },
			{
				id: 'listingRequirements',
				label: t('listing.tabs.listingRequirements'),
			},
			{ id: 'virtualAssets', label: t('listing.tabs.virtualAssets') },
		],
		[t]
	);

	// Данные для табов
	const data = useMemo(
		() => ({
			companiesList: {
				categories: [
					{
						name: t('listing.categories.listed'),
						companies: [
							t('listing.companies.envoys'),
							t('listing.companies.frigate'),
							t('listing.companies.kyrgyzBank'),
							t('listing.companies.manasAirport'),
							t('listing.companies.kyrgyzTelecom'),
							t('listing.companies.electricStations'),
							t('listing.companies.kyrgyzElectric'),
							t('listing.companies.tnkDastan'),
							t('listing.companies.kantCement'),
							t('listing.companies.keregitash'),
							t('listing.companies.bishkeksut'),
							t('listing.companies.uchkun'),
							t('listing.companies.bankAsia'),
							t('listing.companies.kyrgyzInvestmentBank'),
							t('listing.companies.oxus'),
							t('listing.companies.eletCapital'),
							t('listing.companies.dosKredobank'),
						],
					},
					{
						name: t('listing.categories.delisted'),
						companies: [t('listing.companies.techService')],
					},
				],
			},
			bonds: [
				t('listing.companies.kyrgyzBank'),
				t('listing.companies.bankAsia'),
				t('listing.companies.kyrgyzInvestmentBank'),
				t('listing.companies.oxus'),
				t('listing.companies.eletCapital'),
				t('listing.companies.dosKredobank'),
			],
			stocks: [
				t('listing.companies.kyrgyzBank'),
				t('listing.companies.manasAirport'),
				t('listing.companies.kyrgyzTelecom'),
				t('listing.companies.electricStations'),
				t('listing.companies.kyrgyzElectric'),
				t('listing.companies.envoys'),
				t('listing.companies.tnkDastan'),
				t('listing.companies.kantCement'),
				t('listing.companies.bishkeksut'),
				t('listing.companies.uchkun'),
			],
			financialReports: [
				{
					id: 1,
					name: t('listing.reports.audit2021'),
					url: 'https://drive.google.com/file/d/1MwDgNw4zrOZCEmVn_LWwDHtYCwPnZ5aK/view?usp=sharing',
				},
			],
			listingRequirements: [
				{
					id: 2,
					name: t('listing.requirements.listingRequirements'),
					url: 'https://docs.google.com/document/d/1CmOKIaRUkHQLi751GYIHUAVGklWZ7Vii/edit?usp=sharing',
				},
				{
					id: 3,
					name: t('listing.requirements.appendixNumOne'),
					url: 'https://docs.google.com/document/d/15UYmgdS3-QfSCgUFr4O7DPqPnzDWL5jK/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
				},
				{
					id: 4,
					name: t('listing.requirements.appendixNumTwo'),
					url: 'https://docs.google.com/document/d/15GRSVMlOIp93YBfEpn1BGK1PUJFPylrx/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
				},
				{
					id: 5,
					name: t('listing.requirements.appendixNumThree'),
					url: 'https://docs.google.com/document/d/1HC18zwUp1mUZKRW8CRdLQTQLOI3v5tmu/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
				},
				{
					id: 6,
					name: t('listing.requirements.appendixNumFour'),
					url: 'https://docs.google.com/document/d/1vsUGvND9sQeaTpl5uM9cDhAlj0d9NA1C/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
				},
				{
					id: 7,
					name: t('listing.requirements.appendixNumSix'),
					url: 'https://docs.google.com/document/d/1CQTYW-JFaiKFuEkXQkpwAbR6KlqqGxY5/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
				},
				{
					id: 8,
					name: t('listing.requirements.appendixNumSeven'),
					url: 'https://docs.google.com/document/d/1TBZ8IR0Lowg56Ci6xz6HuFN9omQptuEi/edit?usp=sharing&ouid=106378968670706272956&rtpof=true&sd=true',
				},
			],
			virtualAssets: [
				t('listing.virtualAssets.usdt'),
				t('listing.virtualAssets.btc'),
				t('listing.virtualAssets.trx'),
				t('listing.virtualAssets.eth'),
			],
		}),
		[t]
	);

	// Автоматический выбор первой категории
	React.useEffect(() => {
		if (activeTab === 'companiesList') {
			const defaultCategory = data[activeTab]?.categories?.[0]?.name;
			if (defaultCategory) {
				setActiveCompanyList(defaultCategory);
			}
		}
	}, [activeTab, data]);

	// Рендер контента для активного таба
	const renderContent = () => {
		if (activeTab === 'companiesList') {
			const categories = data[activeTab]?.categories || [];
			return (
				<div className='listing__companies-container'>
					<div className='listing__categories'>
						{categories.map((category) => (
							<button
								key={category.name}
								className={`listing__category-button ${
									activeCompanyList === category.name
										? 'listing__category-button--active'
										: ''
								}`}
								onClick={() => setActiveCompanyList(category.name)}
							>
								{category.name}
							</button>
						))}
					</div>
					<div className='listing__company-list'>
						{categories
							.find((category) => category.name === activeCompanyList)
							?.companies.map((company, index) => (
								<div
									key={`${company}-${index}`}
									className='listing__company-item'
								>
									{company}
								</div>
							))}
					</div>
				</div>
			);
		} else if (
			['financialReports', 'listingRequirements'].includes(activeTab)
		) {
			return (
				<div className='listing__items'>
					{data[activeTab]?.map((item) => (
						<a
							key={item.id}
							href={item.url}
							target='_blank'
							rel='noopener noreferrer'
							className='listing__item'
						>
							{item.name}
						</a>
					))}
				</div>
			);
		} else {
			return (
				<div className='listing__items'>
					{data[activeTab]?.map((item, index) => (
						<div key={index} className='listing__item'>
							{item}
						</div>
					))}
				</div>
			);
		}
	};

	return (
		<div className='listing'>
			<div className='container'>
				<h1 className='listing__title title'>{t('listing.title')}</h1>
				<div className='listing__tabs'>
					{tabs.map((tab) => (
						<button
							key={tab.id}
							className={`listing__tab ${
								activeTab === tab.id ? 'listing__tab--active' : ''
							}`}
							onClick={() => setActiveTab(tab.id)}
						>
							{tab.label}
						</button>
					))}
				</div>
				<div className='listing__content'>{renderContent()}</div>
			</div>
		</div>
	);
};

export default Listing;
